import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  session_data:any;
  constructor() {}

  public isAuthenticated(): boolean {
    const token = sessionStorage.getItem('token');
    this.session_data = JSON.parse(sessionStorage.getItem('petronetUserData'));
    // Check whether the token is expired and return
    // true or false
    if(this.session_data && this.session_data[0].token)
    {
      return true;
    }
    else{
      return false;
    }
    //return !this.jwtHelper.isTokenExpired(this.session_data[0].token);
  }
 
  public getRole(): boolean {
    
    this.session_data = JSON.parse(sessionStorage.getItem('petronetUserData'));
   
    return this.session_data[0].user_type;
  }
 
}
