import { Injectable } from '@angular/core';

import { HttpClientModule, HttpResponse, HttpRequest, HttpParams, HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class LoginServiceService {

  constructor(private http: HttpClient) { }

 

  getAdminlogin(loginid:any,password:any) : Observable <any> 
  {
    return this.http.get(environment.hostUrl + 'adminlogin/'+loginid+'/'+password)
    .pipe(catchError(this.formatErrors));
  }
  getJobDescriptionfordailyplan(userid,startdate, reporttype): Observable<any> {
    return this.http.post(environment.hostUrl + 'getJobDescriptionfordailyplan/', {userid : userid, startdate : startdate, reporttype:reporttype})
      .pipe(catchError(this.formatErrors));
  }
  addDailyPlanTimer(obj): Observable < any >{
    return this.http.post(environment.hostUrl + 'addDailyPlanTimer/', obj)
   .pipe(catchError(this.formatErrors));
  }
checkEmail(email:any) : Observable <any> 
  {
    return this.http.get(environment.hostUrl + 'checkEmail/'+email)
    .pipe(catchError(this.formatErrors));
  }

  get_birthdays(date:any) : Observable <any> 
  {
    return this.http.get(environment.hostUrl + 'getBirthday/'+date)
    .pipe(catchError(this.formatErrors));
  }

  forgotPassword(data) : Observable <any> 
  {
    return this.http.post(environment.hostUrl + 'forgotPassword/',data)
    .pipe(catchError(this.formatErrors));
  }


  getUserLogin(loginid,password) : Observable <any> 
  {
    return this.http.get(environment.hostUrl + 'userlogin/'+loginid+'/'+password)
    .pipe(catchError(this.formatErrors));
  }

  getUserProfilepic(userid) : Observable <any> 
  {
    return this.http.get(environment.hostUrl + 'getCustomerProfilePic/'+userid)
    .pipe(catchError(this.formatErrors));
  }
  resetpassword(data) : Observable <any> 
  {
    return this.http.post(environment.hostUrl + 'resetpassword/', data)
    .pipe(catchError(this.formatErrors));
  }
  

  private formatErrors(error: any)
   {
    return throwError(error.error);
  }

}
