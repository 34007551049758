<div id="spinner">
        <div class="clock"></div>
        <p id="spinnertext">
                <label>Processing, Please wait...</label>
        </p>

</div>


<div class="loginbg">
        <div class="container">
             
                <div class="text-center">

                        <img src="./assets/images/logo_1.png" class="">
                        <!-- <p class="abouttimenet"> Time Management Software</p> -->
                </div>
                <br/>
                <div class="login-wrapper">

                        <!-- <span style="color: #474a59;">
                                  <img src="./assets/images/logo.png" class="logo-img"><b>TIME<span style="color:#c29c51">NET</span></b></span> -->


                        <a [routerLink]="[]" class="btn btn-outline-secondary btn-block btn-google"
                                (click)="signInWithGoogle()" style="font-size:20px;">

                                <img src="assets/images/google-login.png" alt=""> Sign in with Google

                        </a>

                        <div class="mt-4 text-center">

                                <p class="text-secondary"><span><b>OR</b></span></p>
                        </div>

                        <form [formGroup]="validatingForm" class="form">

                                <div class="form-group">
                                        <label class="mb-1 ">Email </label>
                                        <input type="email" id="email" style="display:none"
                                                name="fakeusernameremembered">
                                        <input type="password" id="password" style="display:none"
                                                name="fakepasswordremembered">
                                        <input (keyup.enter)="login()" autocomplete="nope" type="email"
                                                class="form-control logintext" placeholder="Email"
                                                [(ngModel)]="userlogin.login_id" formControlName="login_id">
                                        <div class="mandatory"
                                                *ngIf="validatingForm.get('login_id').hasError('required') && validatingForm.get('login_id').touched">
                                                Email Required.</div>
                                        <div class="mandatory"
                                                *ngIf="validatingForm.get('login_id').hasError('pattern') && validatingForm.get('login_id').touched">
                                                Email not valid.</div>
                                </div>
                                <div class="form-group">
                                        <label class="mb-1 ">Password </label>

                                        <input (keyup.enter)="login()" autocomplete="new-password" type="password"
                                                class="form-control logintext" placeholder="Password"
                                                [(ngModel)]="userlogin.password" formControlName="password">
                                        <div class="mandatory"
                                                *ngIf="validatingForm.get('password').hasError('required') && validatingForm.get('password').touched">
                                                Password Required.</div>
                                </div>


                                <div class="form-group">

                                        <button type="submit" class="btn btn-login btn-block "
                                                (click)="login()">Login</button>
                                </div>



                        </form>
                        <div class="row">
                                <div class="col-md-12 float-right">
                                        <a [routerLink]="['/reset-password']" class="btn-resetpassword">Reset
                                                Password?</a>
                                </div>
                        </div>
                      
                        <div class="row mt-5">
                                <div class="col-md-12 d-flex justify-content-between">
                                        <div>
                                                <div class="form-check">
                                                        <label class="form-check-label">
                                                                <input type="checkbox" class="form-check-input mr-2"
                                                                        [ngModelOptions]="{standalone: true}"
                                                                        [(ngModel)]="cbchecked">
                                                                        I agree to the terms and conditions of the 
                                                                        <a
                                                                        href="assets/pdf/license_agreement.pdf"
                                                                        target="_blank">Chalk License Agreement</a>
                                                        </label>

                                                </div>
                                        </div>
                                        <div>
                                               
                                        </div>
                                </div>

                        </div>
                        

                        <div class="row mt-5">
                                <div class="col-12 text-center">
                                        <small class="text-secondary"> &copy; {{currentYear}}<span class="text-defult-theme">
                                                        Chalk Technologies Inc.</span> All Rights Reserved.</small>
                                </div>
                        </div>
                </div>





        </div>

</div>