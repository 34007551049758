import {
  FacebookLoginProvider,
  GoogleLoginProvider,
} from 'angularx-social-login';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import {
  SocialAuthServiceConfig,
  SocialLoginModule,
} from 'angularx-social-login';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BnNgIdleService } from 'bn-ng-idle'; // import bn-ng-idle service
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { DeactivateGuard } from './user/timesheet-new/deactivate-guard';
import { DeactivateGuardReview } from './user/timesheet-review-new/deactivate-guard-review';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { LoginComponent } from './login/login.component';
import { NgModule } from '@angular/core';
import { NgxEmojiPickerModule } from 'ngx-emoji-picker';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { RoundOffPipe } from 'src/app/user/pipes/round-off.pipe';
import { TokenInterceptor } from './Interceptor/http.interceptor';

@NgModule({
  declarations: [AppComponent, LoginComponent, ForgotPasswordComponent],

  imports: [
    NgxEmojiPickerModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    PasswordStrengthMeterModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SocialLoginModule,
  ],
  providers: [
    DatePipe,
    RoundOffPipe,
    DeactivateGuard,
    DeactivateGuardReview,

    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '193893527122-l47vto3gmf6ihi6tl2pm6af7d3imsq8p.apps.googleusercontent.com' //Test Server
              //'316524600170-ue8go18ubi3nnrns9i8tfvjbe3fqgs8h.apps.googleusercontent.com'  //liver server
            ),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('clientId'),
          },
        ],
        onError: (err) => {
          console.error(err);
        },
      } as SocialAuthServiceConfig,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    // {provide: LocationStrategy, useClass: HashLocationStrategy},
    BnNgIdleService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
