import { Component, HostListener } from '@angular/core';
import { BnNgIdleService } from 'bn-ng-idle'; // import it to your component
import {Router} from '@angular/router'
import Swal  from 'sweetalert2'
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(private bnIdle: BnNgIdleService,private router:Router) { // initiate it in your component constructor
    this.bnIdle.startWatching(72000).subscribe((res) => {
      if(res) {

        console.log('url segment',this.router.url);

        if(this.router.url!='/login' && this.router.url!='/')
        {
          Swal.fire({
            text: "Session Expired!",
            icon: 'warning',
            //showCancelButton: true,
            confirmButtonColor: '#c29c51',
           // cancelButtonColor: '#343a40',
            confirmButtonText: 'Ok'
          }).then((result) => {
            sessionStorage.removeItem('petronetUserData');
            sessionStorage.removeItem('samplegui');            
            this.router.navigate(['login']);
          });
        }

      
      
      }
    });

   
  }
  // @HostListener('window:beforeunload', [ '$event' ])
  // beforeUnloadHandler(event) {
  //   sessionStorage.removeItem('petronetUserData');
  // }
  title = 'Chalk';
}
