import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roundOff'
})
export class RoundOffPipe implements PipeTransform {

  transform(value : any, roundOffBase : any): any {
    
    if(value > 0 && roundOffBase > 0){
      let decimalPart = (value - Math.floor(value))*60;
      
        if(decimalPart > 0){
          let roundedOffMultiple = Math.ceil(decimalPart/roundOffBase)
          let hourCoversionOfBase = roundOffBase/60;
          let newRoundedOffValue = (Math.floor(value))+(roundedOffMultiple * hourCoversionOfBase)

          return Number(newRoundedOffValue.toFixed(2));
        }
        else{
          return Number(value.toFixed(2));
        }
    }

    else{
      return Number(value.toFixed(2));
    }
  }

}
