import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { CustomPreloadingService } from './services/custom-preloading.service'
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AuthGuardService as AuthGuard } from './auth/auth-guard.service';
import { ResetPasswordComponent } from './share/reset-password/reset-password.component';

const routes: Routes = [
  {path:'', component:LoginComponent},
  
  {path:'login', component:LoginComponent},
  { path: 'user',canActivate: [AuthGuard],data:{ preload: true,role: 'u,c,s'}, loadChildren:()=> import('./user/user.module').then(m=>m.UserModule)},
  { path: 'admin',canActivate: [AuthGuard],data:{ preload: true,role: 'a'}, loadChildren:()=> import('./admin/admin.module').then(m=>m.AdminModule)},
  { path: 'db', loadChildren:()=> import('.//db-developer//db-developer.module').then(m=>m.DbDeveloperModule)},

  {path:'reset-password', component:ForgotPasswordComponent},
  {path:'password-reset', component:ResetPasswordComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{preloadingStrategy: CustomPreloadingService})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
