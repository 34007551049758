import { Component, OnInit, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { LoginServiceService } from '../services/login-service.service';
import {FormBuilder,FormGroup,FormControl, Validators} from '@angular/forms';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { SocialAuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  lblsuccess:any;
  lbldanger:any;
  logindetails = [];
  validatingForm: any;
  isFetching =false;
  currentYear = new Date().getFullYear();
  cbchecked:boolean=true;
  userlogin={

   // email:'admin@gmail.com',
   // password:'1234'

     login_id:'',
     password:'',
     user_type:''

   
  }
msg:any;
session_data:any;
lastReviewSelection:any;

  constructor(private authService: SocialAuthService, private loginservice:LoginServiceService  ,private router: Router, private formBuilder: FormBuilder,private ele :  ElementRef ) { 
    this.session_data = JSON.parse(sessionStorage.getItem('petronetUserData'));
    this.lastReviewSelection = JSON.parse(sessionStorage.getItem('lastReviewMode'));        
//     if(this.session_data)
//     {
// if(this.session_data[0].user_type=='a')
// {
//   this.router.navigate(['/admin/admin-dashboard']);
// }
// else{
//   this.router.navigate(['/user/time-sheet']);
// }
//     }
  }
  

  ngOnInit()
   {
    
    this.ele.nativeElement.querySelector('#spinner').style.visibility = 'hidden';  
    this.validation();   
   

   }

   

//validate the control value of text boxes and drop down box.
validation() 
{
  const emailPattern = '[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,64}';
  this.validatingForm = new FormGroup({
   
    login_id: new FormControl(null,[ Validators.required,Validators.pattern(emailPattern)]),
    password:new FormControl(null, Validators.required),
   
  
  });
}


signInWithGoogle(): void {
  if(this.cbchecked==true){
  this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((data)=>{
    if(data)
    {
      console.log('google data',data.email)
      this.userlogin.login_id=data.email;
      this.userlogin.password='';
      this.isFetching = true;
     
        this.loginservice.checkEmail(this.userlogin.login_id).subscribe( (res: any) => {
          // if (res[0].length==1)
           if (res.length==1)  
           {
               res[0].login_method='gmail'
               this.logindetails = res;
                            
               //sessionStorage.setItem('petronetUserData',JSON.stringify(res));            
               console.log('gopi', this.logindetails)
               //this.ele.nativeElement.querySelector('#spinner').style.visibility = 'hidden';
  
               //login by admin
               if(this.logindetails[0].user_type =='a')
               {
                 sessionStorage.setItem('petronetUserData',JSON.stringify(res));
                 this.ele.nativeElement.querySelector('#spinner').style.visibility = 'hidden';
                 this.router.navigate(['/admin/dashboard']);
                 this.clear();
               }
               else
               {
                 //login by user and redirect.
                 sessionStorage.setItem('petronetUserData',JSON.stringify(res));
                 
                 var today = new Date();
  
  
                 var dd = today.getDate();
                 var mm = today.getMonth() + 1; //January is 0!
                 console.log(today);
                 var yyyy = today.getFullYear();
                 if (dd < 10) {
                   dd = 0 + dd;
                 }
                 if (mm < 10) {
                   mm =0 + mm;
                 }
                 
                 var todayDate = yyyy+'-' +mm + '-' + dd;
                 this.loginservice.get_birthdays(todayDate).subscribe( (res: any) => {
                  this.ele.nativeElement.querySelector('#spinner').style.visibility = 'hidden';
                   if(res.result.length!=0)
                   {
                    this.router.navigate(['/user/birthday-wishes']);   
                   }
                   else
                   {
                     if(this.lastReviewSelection)
                     {
                      if(this.lastReviewSelection.reviewMode=='undefined' || this.lastReviewSelection.reviewMode==undefined || this.lastReviewSelection.reviewMode==false)
                      {
                        this.router.navigate(['/user/assignments']);
                      }
                      else{
                        this.router.navigate(['/user/assignments']);
                      }
                     }
                     else
                     {
                      this.router.navigate(['/user/assignments']);
                     }
                   
                    
                   }
                  
                  this.clear();
                 });
                 
               }
           }
           else 
           {
             this.ele.nativeElement.querySelector('#spinner').style.visibility = 'hidden';            
               Swal.fire({
                   //type: 'success',
                   //title: 'Thank you for subscribe!',
                   html: 'Email  not registered in the portal.'
                 });
                 this.clear();
           }
         });
     
     
    }
   
  });
}
else{
  Swal.fire({
    html: 'Please accept the License agreement.'
  });
}
}


   //get login by user and admin
  login()
  {
    if(this.validatingForm.invalid)
    {
     
      this.validatingForm.controls['login_id'].markAsTouched(),
      this.validatingForm.controls['password'].markAsTouched()
     
    }

    else
    {
      if(this.cbchecked==true){
      this.isFetching = true;


    this.ele.nativeElement.querySelector('#spinner').style.visibility = 'visible';
    this.loginservice.getAdminlogin(this.userlogin.login_id,encodeURIComponent(this.userlogin.password)).subscribe( (res: any) => {
         // if (res[0].length==1)
          if (res.length==1)  
          {
            res[0].login_method='db'
              this.logindetails = res;             
              //sessionStorage.setItem('petronetUserData',JSON.stringify(res));            
              console.log('gopi', this.logindetails)
              //this.ele.nativeElement.querySelector('#spinner').style.visibility = 'hidden';
              if(this.logindetails[0].user_type =='a')
              {
                sessionStorage.setItem('petronetUserData',JSON.stringify(res));
                this.ele.nativeElement.querySelector('#spinner').style.visibility = 'hidden';
                this.router.navigate(['/admin/dashboard']);
                this.clear();
              }
              else
              {
                //login by user and redirect.
              sessionStorage.setItem('petronetUserData',JSON.stringify(res));
              let selectedUsersForGettingJobDescription = '{'+this.logindetails[0].id+'}';
              let selectedDate = moment(new Date()).format('YYYY-MM-DD');
              let reportType='daily'
              //login by admin
              this.loginservice.getJobDescriptionfordailyplan(selectedUsersForGettingJobDescription,selectedDate, reportType).subscribe((data:any)=>{
                
                data.forEach((elememt,index)=>{
                  if((elememt.start_time==null ||elememt.start_time=='' || elememt.start_time=='null' || elememt.start_time=='undefined')){
                    console.log(elememt,'fian')
                  }
                  else{
                  
                    if(elememt.end_time==null||elememt.end_time==''){
                      sessionStorage.removeItem('savetimerdata');
                      sessionStorage.removeItem('savedate');
                      var obj = {
                        daily_plan_id : elememt.id,
                        daily_plan_date : elememt.jbbd_createddate,
                        start_time : '',
                        end_time : '',
                        hours_calculated : elememt.timerhours_spend,
                        id : elememt.assigned_to_user_id
                       }
                       this.loginservice.addDailyPlanTimer(obj).subscribe(
                        res => {})
                    }
                  }
                })
              })
               var today = new Date();
               var dd = today.getDate();
               var mm = today.getMonth() + 1; //January is 0!
               console.log(today);
               var yyyy = today.getFullYear();
               if (dd < 10) {
                 dd = 0 + dd;
               }
               if (mm < 10) {
                 mm =0 + mm;
               }
               
               var todayDate = yyyy+'-' +mm + '-' + dd;
               this.loginservice.get_birthdays(todayDate).subscribe( (res: any) => {
                this.ele.nativeElement.querySelector('#spinner').style.visibility = 'hidden';
                 if(res.result.length!=0)
               {
                this.router.navigate(['/user/birthday-wishes']);
               }
               else{
                if(this.lastReviewSelection)
                {
                 if(this.lastReviewSelection.reviewMode=='undefined' || this.lastReviewSelection.reviewMode==undefined || this.lastReviewSelection.reviewMode==false)
                 {
                   this.router.navigate(['/user/assignments']);
                 }
                 else{
                   this.router.navigate(['/user/assignments']);
                 }
                }
                else
                {
                 this.router.navigate(['/user/assignments']);
                }
                
               
               }
              });
                
                this.clear();
              }
          }
          else 
          {
            this.ele.nativeElement.querySelector('#spinner').style.visibility = 'hidden';            
              Swal.fire({
                  //type: 'success',
                  //title: 'Thank you for subscribe!',
                  html: 'Incorrect Username or Password.',
                  confirmButtonText: 'Ok',
                  confirmButtonColor: 'var(--theme-color1)', 

                });
                this.clear();
          }
        },error=>{
          this.ele.nativeElement.querySelector('#spinner').style.visibility = 'hidden'; 
          Swal.fire({
            icon: 'error',
            //title: 'Thank you for subscribe!',
            html: 'Something went wrong.',
            confirmButtonColor: 'var(--theme-color1)', 
          }); 
        });
      }
      else{
        Swal.fire({
          html: 'Please accept the License agreement.'
        });
      }
      }
  }
  clear() {
    this.userlogin.login_id = '';
    this.userlogin.password = '';
    this.userlogin.user_type = '';
    this.validatingForm.reset();
  }
}
