// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
 //hostUrl:'https://petrotel.chalk24.com:9727/', //Liver Server
// hostUrl: 'https://chalktest.apvtechnologies.net:9899/', //for test url

 hostUrl: 'https://chalkdemo.apvtechnologies.net:9799/', //for demo url

 //hostUrl: 'http://localhost:9000/', //for developer url

  //hostUrl: 'http://192.168.0.59:9000/', //for developer url
  env: 'petronet',
  production: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
