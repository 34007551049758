import { CanDeactivate } from '@angular/router';
import { TimesheetReviewNewComponent } from './timesheet-review-new.component';
import {Injectable} from '@angular/core';
export class DeactivateGuardReview implements CanDeactivate<TimesheetReviewNewComponent> {

  canDeactivate(component: TimesheetReviewNewComponent) {
    return component.canDeactivate();
  }






}